.joinContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: #f5f5f5;
    margin: 5rem;
    padding: 2rem;
}
.title{
    margin: 1rem;
}
.loading{
    margin: 10px 0 10px 10px;
    padding: 5px;
}