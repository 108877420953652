.App {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.leftPanel {
    display: flex;
    flex-direction: column;
    width: 70%;
    flex-grow: 1;
    background-color: #ddd;
    padding: 20px;
    margin: 20px;

}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #5a83ea;
    color: white;
    width: 5em;
    align-self: flex-end;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
}
.welcome {
    margin: 0 5px 5px 0;
    padding: 5px;
    font-size: 1.2em;
    font-weight: bold;
}



.rightPanel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    width: 30%;
    flex-grow: 1;
    background-color: #aaa;
    padding: 20px;
    margin: 20px;

}

.tldrawContainer {
    width: 100%;
    height: 90%;
}
.chatContainer{
    height: calc(100% - 120px);
    min-height: 10px;
}
.chatHeader {
    margin: 0 0 0 5px;
    font-size: 1.2em;
    font-weight: bold;
}

.chatMessages {
    overflow-y: auto;
    margin-top: 15px;
    height: calc(100% - 80px);
}

.chatMessage {
    background-color: #f4f4f4;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
}
.chat_input{
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1.2em;
    font-weight: bold;
    width: 85%;
    /*min-height: 40px;*/

}

.waiting{
    font-weight: bold;
    font-size: 1em;
    margin-left: 7px
}